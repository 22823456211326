var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('a-form',{ref:"formLogin",staticClass:"user-layout-login",attrs:{"id":"formLogin","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-tabs',{attrs:{"activeKey":_vm.customActiveKey,"tabBarStyle":{ textAlign: 'center', borderBottom: 'unset' }},on:{"change":_vm.handleTabClick}},[_c('a-tab-pane',{key:"tab1",attrs:{"tab":"账号密码登录"}},[(_vm.isLoginError)?_c('a-alert',{staticStyle:{"margin-bottom":"24px"},attrs:{"type":"error","showIcon":"","message":this.accountLoginErrMsg}}):_vm._e(),(_vm.tenantOpen)?_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['tenantCode', { rules: [{ required: true, message: '请选择租户！' }] }]),expression:"['tenantCode', { rules: [{ required: true, message: '请选择租户！' }] }]"}],staticStyle:{"width":"100%"},attrs:{"size":"large","placeholder":"请选择租户"}},_vm._l((_vm.tenantsList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.code}},[_vm._v(_vm._s(item.name))])}),1)],1):_vm._e(),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'account',
              {
                rules: [{ required: true, message: '请输入帐户名' }, { validator: _vm.handleUsernameOrEmail }],
                validateTrigger: 'change',
              },
            ]),expression:"[\n              'account',\n              {\n                rules: [{ required: true, message: '请输入帐户名' }, { validator: handleUsernameOrEmail }],\n                validateTrigger: 'change',\n              },\n            ]"}],attrs:{"size":"large","type":"text","placeholder":"账号"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              { rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur' },
            ]),expression:"[\n              'password',\n              { rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur' },\n            ]"}],attrs:{"size":"large","type":"password","autocomplete":"false","placeholder":"密码"}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1)],1)],1),_c('a-form-item',[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['rememberMe', { valuePropName: 'checked' }]),expression:"['rememberMe', { valuePropName: 'checked' }]"}]},[_vm._v("自动登录")]),_c('router-link',{staticClass:"forge-password",staticStyle:{"float":"right"},attrs:{"to":{ name: 'forgotPassword' }}},[_vm._v("忘记密码")])],1),_c('a-form-item',{staticStyle:{"margin-top":"24px"}},[_c('a-button',{staticClass:"login-button",attrs:{"size":"large","type":"primary","htmlType":"submit","loading":_vm.state.loginBtn,"disabled":_vm.state.loginBtn}},[_vm._v("确定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }